import React, { useEffect, useState, useRef } from 'react'
import './TeamCarousel.scss'
import TeamCard from '../team-card/TeamCard'

function MobileCarousel({ content }) {
    const carouselMobile = useRef()
    const carouselTrack = useRef()

    const [position, setPosition] = useState({ trackPosition: 0, progress: 0 })
    const [initPosition, setInitPosition] = useState('default')

    useEffect(() => {

        const slider = carouselMobile.current
        const track = carouselTrack.current
        const item = document.getElementsByClassName('team-card-mobile')[0]
        const items = content

        let style = window.getComputedStyle(item);
        let marginLeft = parseFloat(style.marginLeft);
        let marginRight = parseFloat(style.marginRight);

        let margin = marginLeft + marginRight
        let trackWidth = track.getBoundingClientRect().width;
        let itemWidth = item.offsetWidth;

        let allowDrag = window.innerWidth <= 1000 ? true : false
        let pos = initPosition !== "default" ? initPosition.trackPosition : 0;
        let startX, endX;


        let initWidth=window.innerWidth
        // console.log(itemWidth)
        window.addEventListener('resize', () => {
            console.log(window.innerWidth,initWidth)
            if(initWidth===window.innerWidth)return
            // initWidth=window.innerWidth
            ///we have to find a way to check for width change only
            if (window.innerWidth < 500) return;
            if (window.innerWidth <= 1000) {
                allowDrag = true

                //update values
                style = window.getComputedStyle(item);
                marginLeft = parseFloat(style.marginLeft);
                marginRight = parseFloat(style.marginRight);
                margin = marginLeft + marginRight;
                trackWidth = track.getBoundingClientRect().width;
                itemWidth = item.getBoundingClientRect().width;

                pos = 0
                track.style.left = `0px`

                setPosition({ trackPosition: 0, progress: 0 })

                //this is an issue
                // setInitPosition({trackPosition:0,progress:0})
            }
            else {
                allowDrag = false
            }
        })

        slider.addEventListener('mousedown', e => {
            startX = e.pageX;
        });

        slider.addEventListener('mousemove', e => {
            if (startX) {
                endX = e.pageX;
            }
        });

        slider.addEventListener('mouseup', e => {
            if (allowDrag === false) return;
            if (endX && endX > startX) {

                if (pos !== 0) {
                    pos -= itemWidth + margin
                    if (pos < 0) {
                        pos = 0
                    }
                    track.style.left = `-${pos}px`
                    setPosition({ trackPosition: pos, progress: pos / trackWidth * items.length })
                }
                console.log('moving left', pos)

            } else if (endX && endX < startX) {
                if (Math.floor(pos + itemWidth + margin) !== Math.floor(trackWidth)) {
                    pos += itemWidth + margin
                    track.style.left = `-${pos}px`

                    setPosition({ trackPosition: pos, progress: pos / trackWidth * items.length })
                }
                console.log('moving right', pos, itemWidth)
            }

       

            startX = null;
            endX = null;
        });

        document.addEventListener('touchstart', e => {
            startX = e.touches[0].pageX;
        });

        document.addEventListener('touchmove', e => {
            if (startX) {
                endX = e.touches[0].pageX;
            }
        });

        document.addEventListener('touchend', e => {
            if (allowDrag === false) return;
            //dragged to the left


            if (endX && endX > startX) {
                if (pos !== 0 && pos > 0) {
                    pos -= itemWidth + margin
                    if (pos < 0) {
                        pos = 0
                    }
                    track.style.left = `-${pos}px`
                    setPosition({ trackPosition: pos, progress: pos / trackWidth * items.length })
                }
            }

            //dragged to the right
            else if (endX && endX < startX) {
                if (Math.floor(pos + itemWidth + margin) !== Math.floor(trackWidth)) {
                    pos += itemWidth + margin
                    track.style.left = `-${pos}px`
                    setPosition({ trackPosition: pos, progress: pos / trackWidth * items.length })
                }

            }

            startX = null;
            endX = null;
        });

        // }

        return (() => {
            // setPosition({trackPosition:0,progress:0})

        })
    }, [initPosition])


    const scrollToPosition = (i) => {
        const track = carouselTrack.current
        let trackWidth = track.getBoundingClientRect().width;
        const items = content
        const item = document.getElementsByClassName('team-card-mobile')[0]

        let itemWidth = item.getBoundingClientRect().width;
        let style = window.getComputedStyle(item);
        let marginLeft = parseFloat(style.marginLeft);
        let marginRight = parseFloat(style.marginRight);
        let itemTotalWidth = itemWidth + marginLeft + marginRight;
        console.log(itemWidth)

        let pos = itemTotalWidth * i;

        // console.log(pos/trackWidth )

        track.style.left = `-${pos}px`;
        setPosition({ trackPosition: pos, progress: pos / trackWidth * items.length })
        setInitPosition({ trackPosition: pos, progress: pos / trackWidth * items.length })


    }

    // console.log(position.progress)

    return (

        <div className='team-carousel-mobile-container'>
            <div ref={carouselMobile} className='team-carousel-mobile'>
                <div ref={carouselTrack} className='team-carousel-mobile-track'>
                    {
                        content?.map(({ img, name, description, role }) => {
                            return (
                                <TeamCard key={Math.random()} type='mobile' img={img} name={name} description={description} role={role}/>
                            )
                        })
                    }
                </div>


            </div>
            <div className='team-carousel-mobile-progress'>
                {
                    Array.from(Array(content.length).keys())
                        .map((e, i) => {
                            return (
                                <div onClick={() => { scrollToPosition(i) }} key={i} style={Math.floor(position.progress) === i ? { backgroundColor: "#EBFE74", height: '20px', width: '20px' } : { backgroundColor: '#D9D9D9' }}></div>
                            )
                        }
                        )
                }
            </div>
        </div>


    )
}

export default MobileCarousel