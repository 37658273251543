import React, { useEffect, useState, useRef } from 'react'
import './TeamCarousel.scss'
import TeamCard from '../team-card/TeamCard'
import img1 from '../../../../../assets/images/team/1.gif'
import img2 from '../../../../../assets/images/team/2.gif'
import img3 from '../../../../../assets/images/team/3.gif'


import arrowLeft from '../../../../../assets/images/arrow-left.png'
import arrowRight from '../../../../../assets/images/arrow-right.png'
import MobileCarousel from './TechCouncilMobileCarousel'

const content = [
    {
        name: 'Artem',
        description: "Artem's passion and vision for Pixelated Robot NFT are evident in the design and concept of each robot. Inspired by the worlds of science fiction and technology, each Pixelated Robot NFT is a unique creation that captures the imagination of collectors and enthusiasts who share a love for space, games, and robots.",
        role:'Artist',
        img: img1
    },
    {
        name: 'Robo Dad',
        description: "As the leader of the Pixelated Robot NFT project, Artem's father has been responsible for overseeing the overall direction of the project, setting ambitious goals, and making critical decisions that have driven its success.",
        role:'Leader',
        img: img2
    },
    {
        name: 'Robo Mom',
        description: "Artem's mother has been a driving force in the project, providing essential support, guidance, and encouragement to her son and the rest of the family team. Her passion for digital art and technology has been infectious, inspiring the team to stay motivated and focused on achieving their goals.",
        role:'Advisor',
        img: img3
    },
]

function TechCouncil() {
    const [position, setPosition] = useState(0)
    const [groups, setGroups] = useState([])
    const [maxPosition, setMaxPosition] = useState(Math.ceil(content.length / 4))
    const carousel = useRef()


    useEffect(() => {
        let groups = []
        let startPosition = 0
        for (let i = 0; i < maxPosition; i++) {
            // console.log(i)
            groups[i] = content.slice(startPosition, startPosition + 4)
            startPosition += 4
        }
        setGroups(groups)

        // window.addEventListener('resize',()=>{
        //     if(window.innerWidth<=1250&&window.innerWidth>500){
        //         let groups = []
        //         let startPosition = 0
        //         for (let i = 0; i < Math.ceil(content.length / 3); i++) {
        //             // console.log(i)

        //             groups[i] = content.slice(startPosition, startPosition + 3)
        //             startPosition += 3
        //         }
        //         console.log(groups)
        //         setGroups(groups)

        //         setMaxPosition(Math.ceil(content.length /3))
        //     }
        // })


    }, [])

    const forward = () => {
        let currentPos = position
        carousel.current.style.opacity = 0;
        setTimeout(() => {
            currentPos += 1
            setPosition(currentPos)
            carousel.current.style.opacity = 1;

        }, [300])
    }
    const backward = () => {
        let currentPos = position
        carousel.current.style.opacity = 0;
        setTimeout(() => {
            currentPos -= 1
            setPosition(currentPos)
            carousel.current.style.opacity = 1;

        }, [300])
    }

    return (
        <div className='team-carousel team '>
            <div className='team-section-title-2 ffam-vcr fs-xlm fc-blue'>TEAM</div>
            <p className='team-section-semi-title ffam-vcr fw-semi-bold fs-s  fc-white'>
                The Pixelated Robot NFT project owes its success to the tireless efforts of Artem's family team, who have been a constant source of support, guidance, and resources. Artem's parents have played a pivotal role in bringing his vision to life, and their unwavering support has helped the project to thrive.
            </p>
            <div className='team-carousel-container'>
                {
                    position !== 0 &&
                    <button onClick={() => backward()} className='team-carousel-backward-btn ffam-nunito fw-med fs-ml'>
                        <img src={arrowLeft} />
                    </button>
                }
                <div ref={carousel} className='team-carousel-inner'>
                    {
                        groups[position]?.map(({ img, name, description,role }) => {
                            return (
                                <TeamCard key={description} img={img} name={name} description={description} role={role}/>
                            )
                        })
                    }
                </div>

                <MobileCarousel content={content} />

                {
                    position !== (maxPosition - 1) &&
                    <button onClick={() => forward()} className='team-carousel-forward-btn ffam-nunito fw-med fs-ml'>
                        <img src={arrowRight} />
                    </button>
                }
            </div>
            
        </div>
    )
}

export default TechCouncil