import React, { useRef, useState } from 'react';
import './FaqSection.scss'

function Faq({answer,question,noExpand}) {

    const faqQuestion=useRef()
    const faqAnswer=useRef()
    const faqAnswerInner=useRef()
    const [faqOpen,setFaqOpen]=useState(false)

    const expand=()=>{
        if(noExpand===true)return
        if(!faqOpen){
            faqAnswer.current.style.height=`${faqAnswerInner.current.offsetHeight}px`;
        }
        else{
            faqAnswer.current.style.height=`0px`;
        }
        setTimeout(()=>{

        },[400])
        setFaqOpen(!faqOpen)
    }
   
    return (
        <div onClick={expand} className='faq' >
            <div ref={faqQuestion}className={noExpand!==true?'faq-question':'faq-question faq-question-no-expand'}>
                <span className='ffam-poppins fw-semi-bold faq-question-title'>
                    {question}
                </span>
                {
                    !noExpand&&<span className='faq-section-plus ffam-poppins fw-reg' style={{transform:faqOpen?'rotate(45deg)':'rotate(0deg)'}} >+</span>
                }

            </div>
            <div ref={faqAnswer} className='faq-answer'>
                <div ref={faqAnswerInner} className='faq-answer-inner'>
                    <div className='ffam-poppins fw-med fc-l-gray'>
                       {answer.split('<linkhere>').map((ans,index)=>{
                            if(ans.includes('.eth')){
                                return (<a key={index} href={`https://etherscan.io/enslookup-search?search=${ans}`}>{ans}</a>)
                            }
                            else{
                                return ans.split('<splithere>').map((split,i)=>{
                                    // console.log(split)
                                    if(ans.split('<splithere>').length>1&&i!==ans.split('<splithere>').length-1){
                                        return(
                                            <span  key={i}>
    
                                                <span >{split}</span>
                                                <br/>
                                                <br/>
                                            </span>
                                        )
                                    }
                                    else{
                                        return(
                                            <span  key={i}>
    
                                                <span >{split}</span>
                                            
                                            </span>
                                        ) 
                                    }
                                })
                            }
                       })}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Faq;
