
import './App.scss';

import Home from './pages/home/Home'
import News from './pages/news/News'
import Blogs from './pages/blogs/Blogs';
import Vip from './pages/vip/Vip';
import Header from './components/header/Header'
import { Routes, Route } from "react-router-dom";

function App() {
  
  return (
    <div className="app">
      <Header/>
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/news" element={<News/>} />
        <Route path="/blogs" element={<Blogs/>} />
        <Route path="/vip" element={<Vip/>} />

        {/* <Route path="/" element={<Home/>} />
        <Route path="/" element={<Home/>} /> */}

      </Routes> 
    </div>  
  );
}

export default App;
