import React, { useState } from 'react'
import './AboutSection.scss'
import aboutPng from '../../../../assets/images/about-section/about.gif'
import Carousel from '../../../../components/project-carousel/Carousel'

import arrowLeft from '../../../../assets/images/arrow-left.png'
import arrowRight from '../../../../assets/images/arrow-right.png'
import { useNavigate } from 'react-router-dom'
const content = [
    'Welcome to the world of Pixelated Robot NFT, a unique digital art collection created by Artem, a 15-year-old boy who loves coding, space, games, and robots. With the support of his family team, Artem has created a collection of distinctive and visually appealing pixelated robots that are sure to captivate collectors and enthusiasts alike.',
    "Artem's passion for digital art and technology is evident in every aspect of the Pixelated Robot NFT project. His vision is to create a community of like-minded individuals who share his love for robots, science, and gaming. He aims to bring together enthusiasts, collectors, and artists to celebrate creativity, innovation, and exploration.",
    "The Pixelated Robot NFT project is just the beginning for Artem and his family team. They have plans to expand the project in exciting new ways, including building a blockchain game that allows players to interact with the robots in unique and engaging ways. This will provide a completely new way for collectors to engage with the Pixelated Robot NFT collection while also helping to increase its value.",
    "At the heart of the Pixelated Robot NFT project is a commitment to quality, creativity, and innovation. Artem and his family team have worked tirelessly to create a collection of digital art pieces that is unique, visually stunning, and distinct from other digital art collections. Their passion and dedication are evident in the quality and design of each robot.",
    "By investing in a Pixelated Robot NFT, you're not just acquiring a digital art piece – you're becoming part of a community of robot technics, science, and game lovers. You're joining a movement that celebrates creativity, innovation, and exploration. You're investing in the future of digital art and technology.",
    // "Thank you for joining us on this exciting journey, and we look forward to sharing more with you as we continue to grow and expand the Pixelated Robot NFT project."
]

function AboutSection() {
    const [index, setIndex] = useState(0)
    const navigateTo = useNavigate()
    const goForward = () => {
        setIndex(index + 1)
    }
    const goBackward = () => {
        setIndex(index - 1)
    }
    const openLink=()=>{
        window.open('https://opensea.io/collection/terabots-1')
    }
    return (
        <div className='about-section'>
            <div className='about-section-inner'>
                <div className='about-section-row-1'>
                    <div className='about-section-row-1-left '>
                        <div className='about-section-title ffam-vcr fs-xl fc-blue'>
                            <span className='fw-bold'>TERA</span><span className='fw-reg '>BOTS</span>
                        </div>

                        <div className='about-section-carousel-wrapper'>
                            {index !== 0 &&
                                <img onClick={goBackward} src={arrowLeft} className='about-section-arrow-left' />
                            }

                            <div className='about-section-row-1-paragraph ffam-vcr fs-s fw-med fc-white'>
                                {content[index].split('\n').map((content, i) => {
                                    return (
                                        <p key={i}>
                                            <span>{content}</span>
                                            <br />
                                        </p>
                                    )
                                })}
                            </div>
                            {index !== content.length - 1 &&
                                <img onClick={goForward} src={arrowRight} className='about-section-arrow-right' />
                            }

                        </div>

                        <div className='about-mobile-progress'>
                            {
                                Array.from(Array(content.length).keys())
                                    .map((e, i) => {
                                        return (
                                            <div onClick={() => { setIndex(i) }} key={i} style={Math.floor(index) === i ? { backgroundColor: "#EBFE74", height: '20px', width: '20px' } : { backgroundColor: '#D9D9D9' }}></div>
                                        )
                                    }
                                    )
                            }
                        </div>


                        <div className='about-section-btn-wrapper-row-1'>
                            <button onClick={() => {openLink('https://opensea.io/collection/terabots-1')}} className='about-section-row-1-btn-1'>
                                <span className='ffam-vcr fc-black fs-s  fw-extra-bold'>
                                    SEE THE ART
                                </span>
                            </button>
                            <button onClick={() => {navigateTo('/news')}} className='about-section-row-1-btn-1'>
                                <span className='ffam-vcr fc-black fs-s  fw-extra-bold'>
                                    NEWS
                                </span>
                            </button>
                            <button onClick={() => {navigateTo('/blogs')}} className='about-section-row-1-btn-1'>
                                <span className='ffam-vcr fc-black fs-s  fw-extra-bold'>
                                    BLOGS
                                </span>
                            </button>
                            <button onClick={() => {navigateTo('/vip') }} className='about-section-row-1-btn-1'>
                                <span className='ffam-vcr fc-black fs-s  fw-extra-bold'>
                                    VIP
                                </span>
                            </button>
                        </div>
                    </div>
                    <div className='about-section-row-1-right'>
                        <img src={aboutPng} />
                    </div>
                </div>
                {/* <Carousel /> */}

            </div>
        </div>
    )
}

export default AboutSection