import React, { useEffect, useState } from "react";
import "./Background.scss";
// import bgImg from '../../assets/images/background.gif'
import bgImgWebm from '../../assets/images/background.gif'
import bgImgMp4 from '../../assets/images/background.mp4'
import Carousel from "../project-carousel/Carousel";
function Background() {
  return (
    <div className="background">
      {/* <div className="background-left"></div>
      <div className="background-right"></div> */}

      <div className='main-bg-img'>
        <img src={bgImgWebm}/>
        {/* <video autoPlay playsInline loop muted>
          <source src={bgImgWebm}></source> */}
          {/* <source src={bgImgMp4}></source> */}
        {/* </video> */}
        {/* <img src={bgImg} /> */}
      </div>
      <div className="app-bg-carousel">
        <Carousel/>
      </div>
    </div>
  );
}

export default Background;
