import React, { useEffect, useState } from "react";
import Timer from "../../misc/timer";
import "./Carousel.scss";

import img1 from "../../assets/images/carousel/1.gif";
import img2 from "../../assets/images/carousel/2.gif";
import img3 from "../../assets/images/carousel/3.gif";
import img4 from "../../assets/images/carousel/4.gif";
import img5 from "../../assets/images/carousel/5.gif";
import img6 from "../../assets/images/carousel/1.gif";
import img7 from "../../assets/images/carousel/2.gif";
import img8 from "../../assets/images/carousel/3.gif";
import img9 from "../../assets/images/carousel/4.gif";
import img10 from "../../assets/images/carousel/5.gif";


let carousel1Imgs = [
  {
    src: img1,
  },
  {
    src: img2,

  },
  {
    src: img3,

  },
  {
    src: img4,

  },
  {
    src: img5,

  },
  {
    src: img6,

  },
  {
    src: img7,

  },
  {
    src: img8,

  },
  {
    src: img9,

  },
  {
    src: img10,

  },


  //add here

  //////////
  {
    src: img1,
  },
  {
    src: img2,

  },
  {
    src: img3,

  },
  {
    src: img4,

  },
  {
    src: img5,

  },
  {
    src: img6,

  },
  {
    src: img7,

  },
  {
    src: img8,

  },
  {
    src: img9,

  },
  {
    src: img10,

  },
 

];

function Carousel() {

  const [timer,setTimer]=useState({})
  useEffect(() => {


    let carousel1 = document.getElementById('carousel-track-1');


    let appWidth = window.innerWidth;

    let pos1 = 0;

    const initWidth=window.innerWidth
    window.addEventListener('resize', () => {
      if(initWidth===window.innerWidth)return
      pos1 = 0;
      appWidth = window.innerWidth;
    })
    
    let timer = new Timer(() => {
      pos1 += 0.7;
  
      carousel1.style.left = `-${pos1}px`

      if (Math.floor(pos1) === (document.getElementById('carousel-track-1').offsetWidth - appWidth)) {
        carousel1.style.left = `-${(document.getElementById('carousel-track-1').offsetWidth / 2) - appWidth}px`;
        pos1 = (document.getElementById('carousel-track-1').offsetWidth / 2) - appWidth
      }

    }, [10]);

    setTimer(timer)

    return(()=>{
      timer.stop()
    })
  }, []);



  return (
    <div className="carousel">
      <div className='carousel-roadmap-bg'>

      </div>
      <div className="carousel-inner ">
        <div className="carousel-wrapper-1">
          <div id='carousel-track-1' className="carousel-track-1">
            {
              carousel1Imgs.map(({ src }) => {
                return (
                  <img key={`${Math.random()}`} className="carousel-img" src={src} />
                )
              })
            }
          </div>
        </div>

      </div>
    </div>
  );
}

export default Carousel;
