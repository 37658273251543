
import './Header.scss';

import { useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState } from 'react';

import logo from '../../assets/images/logo/Ohai-logo.png'
import mobileLogo from '../../assets/images/logo/Ohai-logo-mobile.png'

// import twitterLogo from '../../assets/images/social/twitter.png'
import twitterMobile from '../../assets/images/social/twitter-large.png'
import walletImg from '../../assets/images/header/wallet-img.png'
import walletImg2 from '../../assets/images/header/clarity-wallet-line.svg'

import menuImg from '../../assets/images/header/menu-icon.png'
import closeImg from '../../assets/images/header/close-icon.svg'
import mobileNftImg from '../../assets/images/header/marketing-nft.png'
import arrowDown from '../../assets/images/header/drop-down-icon.svg'
import HeaderMobileDropDown from './header/HeaderMobileDropDown';
function Header() {
    const [headerVisible,setHeaderVisible]=useState(true)
    const navigate = useNavigate()
    const location=useLocation()
    useEffect(()=>{
        
        if(location.pathname!=='/'){
            setHeaderVisible(false)

        }
        else{
            setHeaderVisible(true)

        }
    },[location])
    const [width, setWidth] = useState(window.innerWidth)
    const [headerMobileOpen, setHeaderMobileOpen] = useState(false)

    const appLocation = useLocation()

    useEffect(() => {
        window.addEventListener('resize', () => {
            setWidth(window.innerWidth)
        })
    }, [])


    const openLink = (link) => {
        window.open(link)
    }

    const openNavBar = () => {
        let mobileNav = document.getElementsByClassName('header-mobile-nav')[0]
        let hamburgerMenu = document.getElementById('header-hamburger-menu')
        let hamburgerClose = document.getElementById('header-hamburger-close')


        if (headerMobileOpen === false) {
            mobileNav.style.display = 'block'
            hamburgerMenu.style.opacity = '0'
            hamburgerClose.style.display = 'flex'

            setTimeout(() => {
                mobileNav.style.opacity = '1'
                hamburgerClose.style.opacity = '1'
            }, [15])
        }
        else {
            mobileNav.style.opacity = '0'
            hamburgerClose.style.opacity = '0'
            hamburgerMenu.style.opacity = '1'

            setTimeout(() => {
                mobileNav.style.display = 'none'
                hamburgerClose.style.display = 'flex'

            }, [320])
        }

        setHeaderMobileOpen(!headerMobileOpen)
    }




    const navigateTo = async (location) => {
        const header = document.getElementsByClassName('header')[0]
        var height = header.offsetHeight
        var container = window
        var offsetTop;

        if (window.innerWidth < 1190) {
            openNavBar()
        }
        console.log(location)

   
        switch (location) {
        
            case "ABOUT":
                offsetTop = document.getElementsByClassName("about-section")[0].offsetTop - height - 20;
                container.scroll({ top: offsetTop, left: 0, behavior: "smooth" });
                break;

            case "TEAM":
                offsetTop = document.getElementsByClassName("team-section")[0].offsetTop - height;
                container.scroll({ top: offsetTop, left: 0, behavior: "smooth" });
                break;
            case "ROADMAP":
                offsetTop = document.getElementsByClassName("roadmap")[0].offsetTop - height - 20;
                container.scroll({ top: offsetTop, left: 0, behavior: "smooth" });
                break;
            case "FAQS":
                offsetTop = document.getElementsByClassName("faq-section")[0].offsetTop - height - 20;
                container.scroll({ top: offsetTop, left: 0, behavior: "smooth" });
                break;
            default:
                break;
        }

    }



    return (
        <div className="header" style={{opacity:headerVisible?'1':'0'}}>

            <div className="header-inner">

                {/* <div className='header-left'>
                    <div onClick={() => { navigateTo('/') }} className='header-desktop-logo-wrapper'>
                        <img src={logo} />
                        <div className='header-left-text fs-header fc-white ffam-nunito fw-black'>OHAI</div>
                    </div>
                </div> */}
                <div className='header-middle'>
                    
                    <button onClick={() => { navigateTo('ABOUT') }} className='fs-header fc-white ffam-vcr fw-black'>
                        ABOUT
                    </button>
                    <button onClick={() => { navigateTo('TEAM') }} className='fs-header fc-white ffam-vcr fw-black'>
                        TEAM
                    </button>
                    <button onClick={() => { navigateTo('ROADMAP') }} className='fs-header fc-white ffam-vcr fw-black'>
                        ROADMAP
                    </button>
                    <button onClick={() => { navigateTo('FAQS') }} className='fs-header fc-white ffam-vcr fw-black'>
                        FAQS
                    </button>

                </div>
                <div className='header-right'>
                    <button onClick={() => {openLink('https://opensea.io/collection/terabots-1')}} className='header-mint-btn ffam-quick fs-s fw-bold '>
                        <span className='text-3xl font-bold underline'>
                            OPENSEA
                        </span>
                    </button>
                </div>


                <button onClick={() => { openNavBar() }} className='header-hamburger' id="header-hamburger-menu">
                    <img src={menuImg} />
                </button>

                <button onClick={() => { openNavBar() }} className='header-hamburger' id="header-hamburger-close">
                    <img src={closeImg} />
                </button>


                <div className='header-mobile-nav'>
                    <div className='header-mobile-nav-inner '>
                        <div className='header-mobile-nav-top'>
                         
                            <div className='about-section-title ffam-vcr fs-xl fc-yellow'>
                                <span className='fw-bold'>ROBO</span><span className='fw-reg '>MATES</span>
                            </div>
                        
                        </div>

                        <button className='header-mobile-nav-btn fs-ls ffam-vcr fw-bold fc-white' onClick={() => { navigateTo('ABOUT') }}>ABOUT</button>
                        <button className='header-mobile-nav-btn fs-ls ffam-vcr fw-bold fc-white' onClick={() => { navigateTo('ROADMAP') }}>ROADMAP</button>
                        <button className='header-mobile-nav-btn fs-ls ffam-vcr fw-bold fc-white' onClick={() => { navigateTo('TEAM') }}>TEAM</button>
                        <button className='header-mobile-nav-btn fs-ls ffam-vcr fw-bold fc-white' onClick={() => { navigateTo('FAQS') }}>FAQS</button>


                        <button className='header-mint-btn' onClick={() => openLink('')}>
                            <span className='ffam-vcr '>
                                OPENSEA
                            </span>
                        </button>

                    </div>
                </div>
                {/* <span style={{ color: 'white', position: 'absolute', left: '2px' }} className='ffam-poppins fw-bold'>{width}</span> */}
            </div>
        </div>
    );
}

export default Header;
