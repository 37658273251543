import React, { useEffect, useRef, useState } from 'react'
import './TeamCard.scss'
import linkedingImg from '../../../../../assets/images/social/linkedin.svg'
import twitterMobileImg from '../../../../../assets/images/social/twitter.svg'

function TeamCard({ name, description, img, type, role }) {
    const [cardExpanded, setCardExpanded] = useState(false)
    const [editedDesc, setEditedDesc] = useState('')

    const [originalHeight, setOriginalHeight] = useState(null)

    const card = useRef('')
    const paragraph = useRef('')

    useEffect(() => {
        // if(!card)return
        let shortDesc = description.slice(0, 0) 
        setEditedDesc(shortDesc)
        setOriginalHeight(card?.current?.offsetHeight)

        window.addEventListener('resize',()=>{
            setOriginalHeight(card?.current?.offsetHeight)
        })
     
    }, [])
    // console.log(originalHeight)
    const expand = () => {
        // console.log(originalHeight)
        if(!cardExpanded){
            setEditedDesc(description)
            card.current.style.height = 'fit-content'
            // card.current.style.background = 'rgba(131, 255, 60, 0.993)'
            if(window.innerWidth<1000){
                paragraph.current.style.paddingBottom = '100px'
             
            }
            else{
                paragraph.current.style.paddingBottom = '50px'

            }
            setCardExpanded(!cardExpanded)
        }
        else{
            let shortDesc = description.slice(0, 0) 
            setEditedDesc(shortDesc)
            card.current.style.height = `${originalHeight}px`
            // card.current.style.background = '#EBFE74'

            paragraph.current.style.paddingBottom = '0px'

            setCardExpanded(!cardExpanded)
        }
    }
    // console.log(role)
    return (
        <div ref={card} className={type !== 'mobile' ? 'team-card' : 'team-card team-card-mobile'} >
            <div className='team-card-background'></div>
            <img className='team-card-img' src={img} />
            
            <div className='team-role-wrapper fs-s ffam-vcr'>
                <span className='fw-bold'>{name}</span> 
                <span className='team-card-divider'>|</span>
                <span className='fc-black'>{role}</span>
            </div>
            
            <p ref={paragraph} className='team-card-desc ffam-poppins fs-xs'>{editedDesc}</p>
            <button onClick={() => expand()} className='team-card-expand-btn'>
                {
                    !cardExpanded ?
                        <span className='fs-ml fw-med'>
                            +
                        </span>
                        :
                        <span className='fs-ml fw-med'>
                            -
                        </span>
                }
            </button>
        </div>
    )
}

export default TeamCard