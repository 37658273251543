import React from 'react'
import './TeamSection.scss'
import TechCarousel from './carousels/TechCouncil'

function TeamSection() {
    return (
        <div className='team-section pre-animate-top'>
            <TechCarousel />
        </div>
    )
}

export default TeamSection