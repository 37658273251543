import React, { useEffect, useState } from 'react'
import './Roadmap.scss'

import img1 from '../../../../assets/images/roadmap/1.svg'
import img2 from '../../../../assets/images/roadmap/2.svg'
import img3 from '../../../../assets/images/roadmap/3.svg'

import controller from '../../../../assets/images/roadmap/controller.png'

function Roadmap() {

    return (
        <div className='roadmap '>
            <div className='roadmap-inner'>
                <div className='fs-xlm ffam-vcr roadmap-title'>ROADMAP</div>
                <div className='roadmap-section-1 pre-animate-top'>
                    <div className='roadmap-section-3-left'>
                        <img src={img2} />
                    </div>
                    <div className='roadmap-section-3-right'>
                        <span className='ffam-vcr fs-m fw-bold fc-blue'>
                            BUILDING A BLOCKCHAIN GAME
                        </span>

                        <p className='ffam-vcr fw-bold fs-xs fc-white'>
                            We will build a blockchain game that is inspired by the Pixelated Robot NFT collection. The game will allow players to interact with the robots in a unique and exciting way, and it will be designed to integrate with the blockchain technology used for the NFTs.
                        </p>
                    </div>
                </div>
                <div className='roadmap-section-2 pre-animate-top'>
                    <div className='roadmap-section-3-right'>
                        <span className='ffam-vcr fs-m fw-bold fc-blue'>
                            CHARITY DONATIONS
                        </span>

                        <p className='ffam-vcr fw-bold fs-xs fc-white'>
                            A portion of the proceeds will be donated from our Pixelated Robot NFT sales to charity organizations that align with our project's vision and goals. This can help to give back to the community while also increasing the overall value and appeal of our digital art pieces.
                        </p>
                    </div>
                    <div className='roadmap-section-3-left'>
                        <img src={img3} />
                    </div>
                </div>
                <div className='roadmap-section-3 pre-animate-top'>
                    <div className='roadmap-section-3-left'>
                        <img src={img1} />
                    </div>
                    <div className='roadmap-section-3-right'>
                        <span className='ffam-vcr fs-m fw-bold fc-blue'>
                            VIRTUAL REALITY INTEGRATION
                        </span>

                        <p className='ffam-vcr fw-bold fs-xs fc-white'>
                            Virtual reality (VR) technology will be integrated into our digital art pieces, allowing collectors to experience them in a completely immersive way. This can provide a unique and exciting way for collectors to engage with the art and increase its value.
                        </p>
                    </div>
                  
                </div>
            </div>
        </div>
    )
}

export default Roadmap