
import './Footer.scss';
import footerLogo from '../../assets/images/footer/Ohai-logo.png'

function Footer() {

    return (
        <div className="footer">
            <div className="footer-inner">
                <span className='fs-s fc-white ffam-vcr'>@ 2023: TERABOTS</span>
            </div>
        </div>
    );
}

export default Footer;
