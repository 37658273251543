import React from 'react';
import './FaqSection.scss'
import Faq from './Faq'

function FaqSection() {

    return (
        <div className='faq-section'>

            <div className='faq-section-inner pre-animate-top'>
                <div className='fc-black fs-xlm ffam-vcr faq-title'>FAQs</div>
                <Faq
                    question='What is a Pixelated Robot NFT?'
                    answer='A Pixelated Robot NFT is a unique digital art piece created by Artem, a 15-year-old boy who loves coding, space, games, and robots. Each robot is designed to be visually stunning and unique, and it is sold as a non-fungible token (NFT) on the blockchain.'
                />
                <Faq
                    question='What is an NFT?'
                    answer='NFT stands for non-fungible token, which is a digital asset that represents ownership of a unique item or piece of content, such as a digital artwork or collectible.'
                />
                <Faq
                    question='How do I purchase a Pixelated Robot NFT?'
                    answer='You can purchase a Pixelated Robot NFT through the dedicated website or marketplace. Each robot has its unique price, and you can buy it using cryptocurrency.'
                />
                <Faq
                    question='How can I trade my Pixelated Robot NFT?'
                    answer='You can trade your Pixelated Robot NFT on the secondary marketplaces or auction sites. The value of each robot can fluctuate depending on market demand.'
                />
                <Faq
                    question='What are the benefits of owning a Pixelated Robot NFT?'
                    answer='Owning a Pixelated Robot NFT provides ownership and authenticity of a unique digital art piece. As the value of NFTs can fluctuate depending on market demand, owning a Pixelated Robot NFT could potentially increase in value over time.'
                />
                <Faq
                    question='Can I use my Pixelated Robot NFT for commercial purposes?'
                    answer="The use of each Pixelated Robot NFT is at the discretion of the owner. However, the rights to the robot's image and design remain with Artem and his family team."
                />
                <Faq
                    question='What are the plans for the future of the Pixelated Robot NFT project?'
                    answer="The family team behind Pixelated Robot NFT plans to expand the project in exciting new ways, including building a blockchain game and collaborating with other artists and creators to develop new and exciting digital art pieces."
                />
                <Faq
                    question="These are some of the FAQs for the Pixelated Robot NFT project. If you have any additional questions, please don't hesitate to reach out to the dedicated support team or community."
                    answer=""
                    noExpand={true}
                />
            </div>
        </div>
    );
}

export default FaqSection;
