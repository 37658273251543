
import './Home.scss';
import { useEffect } from 'react';
import AboutSection from './sections/about-section/AboutSection';
import Roadmap from './sections/roadmap/Roadmap'
import TeamSection from './sections/team-section/TeamSection'
import FaqSection from './sections/faq-section/FaqSection'
import Footer from '../../components/footer/Footer';
import smoothscroll from "smoothscroll-polyfill";
// import bgImg from '../../assets/images/background2.png'
import bgImg from '../../assets/images/background.gif'
import Background from '../../components/background/Background';
window.__forceSmoothScrollPolyfill__ = true;

function Home() {
    useEffect(() => {
        smoothscroll.polyfill();
    }, []);

    const animate=(element,percent)=>{
        const app = window
        element=document.getElementsByClassName(element)[0]
        let initialTop = element?.offsetTop

        app.addEventListener('scroll', () => {
            
            let currentTop = element?.getBoundingClientRect().top
            if(currentTop < (initialTop*percent)){
                element.classList.add('show')
            }
        });
    }

    useEffect(() => {

        // //the higher the percent the shorter it takes to run the animation
        animate('team-section',1)
        animate('roadmap-section-1',3)
        animate('roadmap-section-2',1.9)    
        animate('roadmap-section-3',1.4)
        animate('faq-section-inner',5.5)


    },[])


    return (
        <div className="home">
            <Background/>
            {/* <div className='main-bg-img'>
                <img src={bgImg}/>
            </div> */}

            {/* <TopSection /> */}
            <AboutSection />
            <TeamSection />
            <Roadmap />
            <FaqSection />
            <Footer type='light' />
        </div>
    );
}

export default Home;
